/**
 * 项目全部使用的正则规则
 */
//    import regRule from '@/utils/reg-rule.js';
const regRule = {
  get mobile() {
    return new RegExp(/^1[3|4|5|6|7|8|9]\d{9}$/);
  }, //移动手机号
  get pwd() {
    return new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,30}$/);
  }, //可输入密码情况,
  get email() {
    return new RegExp(/^[A-Za-z0-9]+[A-Za-z0-9-_.]*@([A-Za-z0-9-_]+)(\.[A-Za-z0-9-_]+){1,4}$/);
  }, //邮箱
  get isExternal() {
    return new RegExp(/^(https?:|mailto:|tel:)/);
  }, //过滤
  get num1() {
    return new RegExp(/^\d+(\.\d{1,2})?$/);
  },
  get num2() {
    return new RegExp(/^\d+$|^\d*\.\d+$/);
  }, //数字或者小数
  get num3() {
    return new RegExp(/^\d{0,5}(\.\d{0,2})?$/);
  }, //0.01-99999.99
  get num4() {
    return new RegExp(/^(1000|[1-9][0-9]{0,2})$/);
  }, //1-1000
  get num5() {
    return new RegExp(/^\d{0,8}$/);
  }, //至多8位数字
  get num6() {
    return new RegExp(/^\d+$/);
  }, // 数字
  get num7() {
    return new RegExp(/^([1-9][0-9]{0,5})$/);
  }, // 至多6位数字
  get num7_2() {
    return new RegExp(/^([1-9][0-9]{0,6})$/);
  },
  get num8() {
    return new RegExp(/^[1-9][0-9]*$/);
  }, // 非0正整数
  get num9() {
    return new RegExp(/^(0|[1-9][0-9]*)$/);
  }, // 正整数
  get pullUrl() {
    return new RegExp(/^(rtmp:\/\/.+)|(rtmps:\/\/.+)/g);
  }, //推流地址
  get codePwd() {
    return new RegExp(/^[0-9a-zA-Z]*$/);
  }, //数字或者字母
  get codePwd2() {
    return new RegExp(/^[0-9a-zA-Z]{1,12}$/);
  }, //1-12位数字或字母
  get time() {
    return new RegExp(/^(([0-9]{1,2}[:]){2}[0-9]{1,2})*$/);
  }, //时间 00:00:00
  get xssJava() {
    //Java入参限制 单独放开空格
    return new RegExp(/^[0-9\s-_.~!*'();:@=+$,/?#\[\])a-z\u4e00-\u9fa5]+$/i);
  },
  get mobileForeign() {
    //国外手机号
    return new RegExp(/^\d{1,15}$/);
  },
  // 字母、数字、下划线组成
  get alphanumericWithUnderscore() {
    return new RegExp(/^[a-zA-Z0-9_]+$/);
  },
  get url() {
    return new RegExp(
      /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?/g
    );
  },
  get customerLink() {
    return new RegExp(/^https:\/\/([\w\u4e00-\u9fa5.-]+\.[a-z]+)(\/[^?#]*)?$/i);
  }
};

export default regRule;
